import React, { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";
import { routes } from "../constants/routes";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Endpoints } from "../constants/Endpoints";
import { increaseCartCount } from "../redux/ActionCreator";
import { toast } from "react-toastify";
import BookNowPopup from "./BookNowPopup";
import { IoIosArrowDropdown } from "react-icons/io";
import dayjs from "dayjs";

const CourseAccordianItem = ({ data, index, relatives, setRelatives,selectedCourse,setSelectedCourse,selectedDate,setSelectedDate}) => {
  const navigate = useNavigate();
  const profileDetails = useSelector(({ app }) => app.profileDetails);
  const dispatch = useDispatch();
  const [batches, setBatches] = useState([]);

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  console.log('selectedDate',selectedDate)
  console.log('selectedCourse',data)
  useEffect(() => {
    fetchBatches();
  }, [dropdownVisible]);

  // Fetch batches for the course
  const fetchBatches = async () => {
    try {
      const res = await axios.get(
        `${Endpoints.BASE_URL}/batches/get/list?course_id=${data.id}`
      );
      console.log('res?.data?.data',res?.data?.data)

      
      if(res?.data?.data && res?.data?.data.length>0){
        let status = false
        for(let i of res?.data?.data){
          if(i.status !== 'Closed'){
            status = true
          }
        }

        // if(!status){
        //   toast.success("All batches closed.");

        // }
        setBatches(res.data.data);
      }
    } catch (error) {
      console.error("Error fetching batches:", error);
    }
  };

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    console.log('dropdownVisible',dropdownVisible)
    setDropdownVisible(!dropdownVisible);
    setRelatives(index)
  };

  // Handle date selection from the dropdown
  const handleDateSelect = async (id) => {
    setDropdownVisible(false);
    console.log("profileDetails", profileDetails)

    // Check if user is logged in
    if (profileDetails.token) {
      try {
        // Add course to cart API call
        const cartResponse = await axios.post(
          `${Endpoints.BASE_URL}/cart/add-cart`,
          {
            course_id: data.id,
            category_id: data.category_id
            // batch_date: date,
          },
          {
            headers: {
              Authorization: `Bearer ${profileDetails.token}`,
            },
          }
        );
    
        // Link batch API call
        console.log(data,"data here")
        console.log("batches", batches)
        const linkBatchResponse = await axios.put(
          `${Endpoints.BASE_URL}/api/courses/linkBatch`,
          {
            candidate_id: profileDetails?.id,
            // cart_id:"andf",
            course_id: data.id,
            requested_batch_id: id,
          }
        );

        dispatch(increaseCartCount()); // Update cart count in Redux store
        // toast.success("Course added to the cart.");
        // setPopupOpen(true); 
      } catch (error) {
        console.error("Error adding course to cart or linking batch:", error);
      }
    } else {
      navigate(routes.LOGIN); // Redirect to login page if user is not logged in
    }
  };

  console.log("batches", batches)

  // Handle "Book Now" button click
  const handleBookNow = () => {

    setPopupOpen(!popupOpen)
    // setDropdownVisible(true); // Show dropdown when booking
  };

  // Close popup handler
  const handleClosePopup = () => {
    setPopupOpen(false); // Close popup
  };

  // Continue booking handler
  const handleContinueBooking = () => {
    setPopupOpen(false); // Close popup
    navigate(`${routes.COURSE_BOOKING}/details`); // Navigate to course booking details page
  };

  function formatDate(dateString) {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    // Function to add ordinal suffix to day
    function getOrdinalSuffix(day) {
        if (day > 3 && day < 21) return 'th'; // Special case for 11-20
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    }

    return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
}

const isHTML = (str) => {
  const doc = new DOMParser().parseFromString(str, "text/html");
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
};

const truncateText = (text, wordLimit) => {
  const words = text.split(' ');
  if (words.length <= wordLimit) return text;
  return words.slice(0, wordLimit).join(' ') + '...';
};

const renderEligibilityContent = () => {
  const eligibilityContent = data?.eligibility;
  if (!eligibilityContent) return null;

  const wordLimit = 50;

  if (isHTML(eligibilityContent)) {
    const doc = new DOMParser().parseFromString(eligibilityContent, "text/html");
    const textContent = doc.body.textContent || "";
    const truncatedContent = truncateText(textContent, wordLimit);

    return (
      <span className="text-[12px] md:text-[16px]" dangerouslySetInnerHTML={{ __html: truncatedContent }} />
    );
  }

  const truncatedContent = truncateText(eligibilityContent, wordLimit);

  return <span className="text-[12px] md:text-[16px]">{truncatedContent}</span>;
};

const allBatchesClosed = batches.every(batch => batch.status === "Closed");
const formattedTitle = data?.slug?.replace(/\s+/g, "-").toLowerCase();
  return (
    <div className={`flex !h-full md:flex-row flex-col ${relatives === index ? "relative" :" "} bg-white drop-shadow-xl p-5 my-8 z-[5]`}>
      <div className="flex flex-col justify-center mr-4">
        <img
          src={data?.cover_image_url}
          className="h-[130px] min-w-[170px] my-auto"
          alt=""
        />
      </div>
      <div className="w-full !h-full">
        <div className="!h-full flex flex-col md:flex-row md:gap-10 justify-between">
          <div className="flex flex-col justify-between w-[85%] !h-full">
          <div className="text-gray-500">
            <p className="text-[#03014C] font-bold">{data?.title}</p>
            <p className="text-[14px] leading-[20px]"><span className="font-bold">Eligibility: </span>{renderEligibilityContent()}</p>
            <p className="text-[14px] -mt-2">
              Duration: <span className="font-bold"> {data?.duration}</span>
            </p>
          
          </div>
            <button className="flex items-center font-bold text-[#205277]"
              onClick={() => {
                navigate(
          // `${routes.COURSE_BOOKING}/${formattedTitle}`,{ state: { id: data.id } } //this will be a new upgrade
          // `${routes.COURSE_BOOKING}${routes.ABOUT}/${data.id}/${formattedTitle}`,
          `${routes.COURSE_BOOKING}/${formattedTitle}-${data.id}`,
          { state: { id: data.id } }  // this is previous code
        )
              }}            
            >
              View Course Details
            </button>
          </div>
          <div className="flex flex-col justify-between py-4">
            <div className=" font-bold flex flex-col">
            <p className="text-[#1550a2] font-bold text-base">Course Fee</p>
            <p className="!text-nowrap text-lg">₹{data?.fees}</p>
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-6">
          { allBatchesClosed ? (<div className="text-sm font-medium">
            <p className="text-sm">
              Batch's are Yet to Schedule
            </p>
            <p className="text-sm">
              Contact our Admissions Office at <span className="font-bold text-base">+91 7025045000</span>
            </p>
          </div>) : 
          (<div className="flex gap-2">
            {/* Select Dropdown Button */}
            <button
              className="bg-[#205277] p-2 px-14 rounded-xl text-white font-bold flex items-center"
              onClick={toggleDropdown}
            >
              {
                selectedDate && selectedDate[data.id] ? formatDate(selectedDate[data.id]) : <span className="flex  items-center">Select Batch <IoIosArrowDropdown className="ml-2" /></span>
              }
              
            </button>

            {/* Book Now Button */}
            <button
              disabled={allBatchesClosed}
              onClick={handleBookNow}
              className={`p-2 px-12 rounded-xl text-white font-bold ${allBatchesClosed ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#205277]'}`}
            >
              Book Now
            </button>

            {/* Book Now Popup Component */}
            <BookNowPopup
              isOpen={popupOpen}
              onClose={handleClosePopup}
              onContinue={handleContinueBooking}
              selectedCourse={selectedCourse}
            />

            {/* Dropdown for selecting batches */}
            {dropdownVisible && relatives===index && (
              <div className="h-auto absolute mt-9 w-60 mx-2 bg-white rounded-lg shadow-xl z-[1000] overflow-y-scroll max-h-[200px]">
                {batches.map((batch) => (
                  <>

                    {batch.status !== "Closed" &&
                    dayjs().isBefore(batch?.start_date) &&
                    batch.available_seats !== 0 &&
                  <div
                    key={batch.id}
                    className="my-2 border-b flex justify-between items-center px-2 cursor-pointer"
                    onClick={() =>{
                      
                      setSelectedDate(prevSelectedDate => ({
                        ...prevSelectedDate,
                        [data.id]: !!batch?.start_date && batch?.start_date 
                      }));
                      setSelectedCourse((prev) => {
                        let arr = [...prev];
                        let presentCourseIndex = arr && arr.length>0 ? arr.findIndex((pre) =>pre?.id === data?.id): -1

                        if (presentCourseIndex !== -1) {
                            arr.splice(presentCourseIndex, 1);
                            arr.push(data);
                          } else {
                            arr.push(data);
                          }

                          return arr;
                      });
                      handleDateSelect(
                        (batch?.id)
                      )
                      }
                    }
                  >
                    <span className="p-2 text-gray-800 hover:bg-gray-200 font-semibold text-[14px]">
                      {(batch?.start_date)}
                    </span>
                    <span className="text-blue-600 text-[12px]">
                      ({batch.seats} seat)
                    </span>
                  </div>
}
                  </>
                ))}
              </div>
            )}
          </div>)
          }
        </div>
      </div>
    </div>
  );
};

export default CourseAccordianItem;
