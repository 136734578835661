import React from 'react'

function  BookingSummary() {
  return (
    <div className="bg-[#FFFFFF]  ">
     <div className="bg-[#2C65E8] h-16 bg-fixed">

        </div>
    <div className="w-[80%] max-md:w-full  mx-auto mt-20 max-sm:mt-5 p-4">
        <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-2xl text-[#707070] max-md:text-xl max-sm:mb-4  font-semibold mb-12">BOOKING SUMMARY</h2>
            <div className="space-y-4">
                <div className="p-4 border-gray-300  shadow-md transform hover:shadow-lg hover:-translate-y-1 transition-all duration-300 ease-in-out box-border border flex justify-between items-center rounded-lg">
                    <div>
                    <h3 className="text-lg max-sm:text-base font-medium">PRE-SEA TRAINING FOR ELECTRO-TECHNICAL OFFICER (ETO)-A1</h3>
                    <p className='text-xs'>Duration: 4 Months</p>
                    <p className='text-xs'>Batch Start Date: 23rd March 2024</p>
                    </div>
                    <div>
                    <p className="text-xl max-sm:text-base font-bold">₹ 20,000</p>
                    </div>
                </div>
                <div className="p-4 border-gray-300  shadow-md transform hover:shadow-lg hover:-translate-y-1 transition-all duration-300 ease-in-out box-border border flex justify-between items-center rounded-lg">
                    <div>
                    <h3 className="text-lg max-sm:text-base font-medium">PRE-SEA TRAINING FOR ELECTRO-TECHNICAL OFFICER (ETO)-A1</h3>
                    <p className='text-sm'>Duration: 4 Months</p>
                    <p className='text-xs'>Batch Start Date: 23rd March 2024</p>
                    </div>
                    <div>
                    <p className="text-xl max-sm:text-base font-bold">₹ 20,000</p>
                    </div>
                </div>
            </div>
            <div className='flex justify-end'>
            <div className='w-[20%] max-sm:w-full   '>
            <div className="mt-6 border-t  pt-4">
                <div className="flex border-b border-[#878787] py-2 justify-between items-center">
                    <span className="text-lg max-sm:text-base font-medium">SUB TOTAL</span>
                    <span className="text-lg max-sm:text-base font-bold">₹ 40,000</span>
                </div>

                <div className="flex border-b border-[#878787] py-2 justify-between items-center mt-2">
                    <span className="text-lg  max-sm:text-base font-medium">TOTAL</span>
                    <span className="text-lg  max-sm:text-base font-bold">₹ 40,000</span>
                </div>
                <div className=' border-b border-[#878787] mt-1'></div>
            </div>
            <div className='flex max-sm:justify-center justify-end'>
            <button className="mt-6 text-[12px] px-4 max-sm:text-base font-semibold w-auto bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition duration-300">PROCEED TO PAYMENT</button>
        </div>
        </div>
        </div>
        </div>
    </div>
    </div>
  )
}

export default BookingSummary