import React, { useState } from "react";
// import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import CourseAccordianItem from "./CourseAccordianItem";

const CourseAccordian = ({ title, courses,selectedCourse,setSelectedCourse ,selectedDate,setSelectedDate}) => {
  const [relatives, setRelatives] = useState(-1)
  const [open, setOpen] = useState(false);
  console.log('courses',courses)
  return (
    <div className="my-8" >
      <div onClick={() => setOpen(!open)} className="bg-[#eff5ff] flex justify-between items-center rounded border px-5 py-3 drop-shadow">
        <h1 className="text-[18px] text-[#205277] font-bold ">{title}</h1>
      </div>
      {open && (
        <>
          {courses.map((item, index) => (
            <CourseAccordianItem data={item} index={index} relatives={relatives} setRelatives={setRelatives} selectedCourse={selectedCourse} setSelectedCourse={setSelectedCourse} setSelectedDate={setSelectedDate} selectedDate={selectedDate}/>
          ))}
        </>
      )}
    </div>
  );
};

export default CourseAccordian;
