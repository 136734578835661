import React, { useEffect, useState } from "react";
import axios from "axios";
import { Endpoints } from "../constants/Endpoints";
import PageBanner from "../Components/PageBanner";
import { Helmet } from "react-helmet-async";
import { routes } from "../constants/routes";
import { useNavigate } from "react-router-dom";

const TNC = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`${Endpoints.CMS_URL}/tnc`);
        console.log(res.data);
        setData(res.data.data[0]);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return (
    <>
      <div className="">
        <div className="  bg-fixed">

        </div>
        <PageBanner imgUrl="/assets/Governing-board-banner.png" />
        <div className="flex items-center justify-center h-auto max-lg:h-auto  ">
          <div className="w-4/5 mt-10  border-gray-300 max-lg:w-full max-lg:px-6  p-6">
            <h2 className="font-semibold text-gray-500 uppercase mb-4">Terms & Conditions</h2>
            <div className="border rounded-lg border-gray-300 p-8">
              <p>
                Welcome to www.lorem-ipsum.info. This site is provided as a service to our visitors and may be used for
                informational purposes only. Because the Terms and Conditions contain legal obligations, please read
                them carefully.
              </p>
              <ol className="list-decimal list-inside mt-4">
                <li className="mt-2">
                  <strong className="uppercase">Your Agreement</strong>
                  <p>
                    By using this Site, you agree to be bound by, and to comply with, these Terms and Conditions. If you
                    do not agree to these Terms and Conditions, please do not use this site.
                  </p>
                  <p>PLEASE NOTE: We reserve the right, at our sole discretion, to change, modify or otherwise alter these Terms and Conditions at any time. Unless otherwise. indicated, amendments will become effective immediately. Please review these Terms and Conditions periodically. Your continued use of the Site following llowing the pastir posting of changes and/or modifications will constitute your acceptance eptance of the revised Terms and Conditions and the reasonableness of these standards for notice of changes. For your information, this page was last updated as of the date at the top of these terms and conditions.</p>
                </li>
                <li className="mt-2">
                  <strong className="uppercase">Privacy</strong>
                  <p>
                    Please review our Privacy Policy, which also governs your visit to this Site, to understand our
                    practices.
                  </p>
                </li>
                <li className="mt-2">
                  <strong >LINKED SITES</strong>
                  <p>
                  This Site may contain links to other independent third-party Web sites ("Linked Sites"). These Linked Sites are provided solely as a convenience to our visitors. Such Linked Sites are not under our control, and we are not responsible sponsible for and does not endorse the content of fsuch Linked Sites, including ing any information or materials contained on such Linked Sites. You will need to make your own independent judgment regarding your interaction with these
                  </p>
                </li>
              </ol>
            </div>
            <div className="flex justify-end items-center mt-4">
      <input
       onClick={()=> navigate(`/course-booking/details`)}
        type="checkbox"
        id="agree"
        className="mr-2 w-6 h-6"
      />
      <label htmlFor="agree" className="font-semibold text-gray-500">
        I Agree
      </label>
    </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default TNC;
