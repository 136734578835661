//  import BookingSummary from "../Pages/BookingSummary";

export const routes = {
  HOME: "/",
  ABOUT: "/about-us",
  ANTI_RAGGING:"/anti-ragging-policy-and-reporting",
  GRIVERANCE_FORM:"/griverance-form",
  ADMISSION_LIST:"/admission-list",
  GOVERNING_BOARD: "/governing-board",
  WHY_EUROTECH: "/why-eurotech",
  AWARDS: "/awards",
  PLACEMENTS: "/placements",
  CONTACT_US: "/contact-us",
  FROM_DESK: "/principal-message",
  FACULTY: "/faculty",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forget-password",
  RESET_PASSWORD: "/reset-password",
  REGISTER: "/register",
  GALLERY: "/gallery",
  COURSES: "/courses-list",
  MISSION_VISION: "/mission-vision",
  ALUMNI: "/alumni",
  COURSE_BOOKING: "/course-booking",
  COURSE_DETAILS: "/details",
  COURSE_TERMS: "/terms",
  COURSE_ABOUT: "/about",
  PROFILE: "/profile",
  MY_COURSES: "/my-courses",
  MY_BATCHES: "/my-batches",
  MY_DOCUMENTS: "/my-documents",
  BATCHES: "/batches",
  PAYMENTS: "/payments",
  MY_PURCHASES: "/my-purchases",
  MY_PROFILE: "/my-profile",
  TNC: "/terms",
  PRIVACY_POLICY: "/privacy-policy",
  DISCLAIMER: "/disclaimer",
  BookingSummary: "/BookingSummary",
  BLOG: "/challenges-and-opportunities-for-maritime-academics",
};
